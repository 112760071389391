import { Router } from 'vue-router';
import { Matomo } from './core/types';

export const getMatomo = function (): Matomo {
  return window.Matomo.getAsyncTracker();
};

export const loadScript = function (
  trackerScript: string,
  crossOrigin?: string
) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.src = trackerScript;

    if (crossOrigin && ['anonymous', 'use-credentials'].includes(crossOrigin)) {
      script.crossOrigin = crossOrigin;
    }

    const head = document.head || document.getElementsByTagName('head')[0];
    head.appendChild(script);

    script.onload = resolve;
    script.onerror = reject;
  });
};

export const getResolvedHref = function (router: Router, path: string) {
  return router.resolve(path).href;
};
