import { App, Plugin } from 'vue';
import { VueMatomo } from './types';

const plugin: Plugin = {
  install: function (app: App, matomo: VueMatomo | null) {
    app.config.globalProperties.$tracker = matomo;
    app.provide('matomoTracker', matomo);
  }
};

export default plugin;
