import { createVueMatomo } from './vue-matomo';
import MatomoVuePlugin from './plugin';
import { MatomoVueConfig, MatomoVueOptions, VueMatomo } from './types';

export {
  MatomoVueConfig,
  MatomoVueOptions,
  VueMatomo,
  MatomoVuePlugin,
  createVueMatomo
};
