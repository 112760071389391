/* eslint-disable no-console */
import { getMatomo, loadScript } from '../utils';
import {
  MatomoConfig,
  MatomoRuntimeOptions,
  MatomoOptions,
  MatomoAction,
  Matomo
} from './types';

const matomoExists = function () {
  // In case of TMS,  we load a first container_XXX.js which triggers aynchronously the loading of the standard Matomo.js
  // this will avoid the error throwed in initMatomo when window.Matomo is undefined
  // if window.Matomo is still undefined when counter reaches 3000ms we reject and go to error

  return new Promise((resolve, reject) => {
    const checkInterval = 50;
    const timeout = 3000;
    const waitStart = Date.now();

    const interval = setInterval(() => {
      if (window.Matomo) {
        clearInterval(interval);

        return resolve('resolved');
      }

      if (Date.now() >= waitStart + timeout) {
        clearInterval(interval);

        reject(
          new Error(
            `[vue-matomo]: window.Matomo undefined after waiting for ${timeout}ms`
          )
        );
      }
    }, checkInterval);
  });
};

export const defaultCoreConfig: MatomoConfig = {
  debug: false,
  disableCookies: false,
  requireCookieConsent: false,
  enableHeartBeatTimer: false,
  enableLinkTracking: true,
  heartBeatTimerInterval: 15,
  requireConsent: false,
  trackerFileName: 'matomo',
  preInitActions: []
};

export const createMatomo = async function (
  setupOptions: Partial<MatomoConfig> & MatomoRuntimeOptions
) {
  const options: MatomoOptions = {
    ...defaultCoreConfig,
    ...setupOptions
  };

  const { host, siteId, trackerFileName, trackerUrl, trackerScriptUrl } =
    options;
  const trackerScript = trackerScriptUrl ?? `${host}/${trackerFileName}.js`;
  const trackerEndpoint = trackerUrl ?? `${host}/${trackerFileName}.php`;

  window._paq = window._paq ?? [];

  window._paq.push(['setTrackerUrl', trackerEndpoint]);
  window._paq.push(['setSiteId', siteId]);

  if (options.requireConsent) {
    window._paq.push(['requireConsent']);
  }

  if (options.userId) {
    window._paq.push(['setUserId', options.userId]);
  }

  if (options.enableLinkTracking) {
    window._paq.push(['enableLinkTracking']);
  }

  if (options.disableCookies) {
    window._paq.push(['disableCookies']);
  }

  if (options.requireCookieConsent) {
    window._paq.push(['requireCookieConsent']);
  }

  if (options.enableHeartBeatTimer) {
    window._paq.push(['enableHeartBeatTimer', options.heartBeatTimerInterval]);
  }

  if (options.cookieDomain) {
    window._paq.push(['setCookieDomain', options.cookieDomain]);
  }

  if (options.domains) {
    window._paq.push(['setDomains', options.domains]);
  }

  options.preInitActions.forEach((action) => window._paq.push(action));
  try {
    await loadScript(trackerScript, options.crossOrigin);
    await matomoExists();
    return getMatomo();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.target) {
      console.error(
        `[vue-matomo] An error occurred trying to load ${error.target.src}. ` +
          'If the file exists you may have an ad- or trackingblocker enabled.'
      );
    }
    console.error(error);
    return null;
  }
};

export {
  MatomoConfig,
  MatomoRuntimeOptions,
  MatomoOptions,
  MatomoAction,
  Matomo
};
