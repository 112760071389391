/* eslint-disable no-console */
import { getMatomo, getResolvedHref } from '../utils';
import { createMatomo, defaultCoreConfig } from '../core';
import { MatomoRuntimeOptions } from '../core/types';
import { RouteLocationNormalized } from 'vue-router';
import {
  MatomoVueConfig,
  MatomoVueOptions,
  SiteSearchFilterResult,
  VueMatomo
} from './types';

const trackUserInteraction = function (
  options: MatomoVueConfig,
  to: RouteLocationNormalized,
  from?: RouteLocationNormalized
) {
  if (typeof options.trackSiteSearch === 'function') {
    const siteSearch = options.trackSiteSearch(to);
    if (siteSearch) {
      trackMatomoSiteSearch(options, siteSearch);
      return;
    }
  }
  trackMatomoPageView(options, to, from);
};

const trackMatomoSiteSearch = function (
  options: MatomoVueConfig,
  { keyword, category, resultsCount }: SiteSearchFilterResult
) {
  const Matomo = getMatomo();

  options.debug && console.debug('[vue-matomo] Site Search ' + keyword);

  Matomo.trackSiteSearch(keyword, category, resultsCount);
};

const trackMatomoPageView = function (
  options: MatomoVueConfig,
  to: RouteLocationNormalized,
  from?: RouteLocationNormalized
) {
  const Matomo = getMatomo();

  let title: string;
  let url;
  let referrerUrl;

  if (options.router) {
    url = getResolvedHref(options.router, to.fullPath);
    referrerUrl = from?.fullPath
      ? getResolvedHref(options.router, from.fullPath)
      : undefined;

    if (to.meta.analyticsIgnore) {
      options.debug && console.debug('[vue-matomo] Ignoring ' + url);
      return;
    }

    options.debug && console.debug('[vue-matomo] Tracking ' + url);
    title = (to.meta.title as string) || url;

    if (referrerUrl) {
      Matomo.setReferrerUrl(window.location.origin + referrerUrl);
    }

    if (url) {
      Matomo.setCustomUrl(window.location.origin + url);
    }

    Matomo.trackPageView(title);
  }
};

const defaultVueConfig: MatomoVueConfig = {
  ...defaultCoreConfig,
  trackInitialView: false,
  trackSiteSearch: false
};

const createVueMatomo = async function (
  config: Partial<MatomoVueConfig> & MatomoRuntimeOptions
) {
  const options: MatomoVueOptions = {
    ...defaultVueConfig,
    ...config
  };

  const matomo = await createMatomo(options);

  if (options.trackInitialView && options.router) {
    const currentRoute = options.router.currentRoute.value;
    trackUserInteraction(options, currentRoute);
  }

  if (options.router) {
    options.router.afterEach(
      (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
        trackUserInteraction(options, to, from);

        if (options.enableLinkTracking) {
          matomo?.enableLinkTracking(true);
        }
      }
    );
  }

  return matomo as VueMatomo;
};

export { createVueMatomo };
